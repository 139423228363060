<template>

  <div class="photo">
 
    <div class="remove_label" @click="remove"><i class="icon-remove"></i></div>

    <img :src="edited_item.size_m">
      
  </div>
  
</template>


<script>

export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      edited_item: this.item
    };
  }, 

  mounted () {
   
  },
  methods: {
    remove(){
      this.$emit('remove'); 
    },
    change(){
      this.$emit('update:item', this.edited_item)
    }
  }
}
</script>
 

 
<style scoped>


.photo{
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    border: 1px rgb(237, 237, 237) dotted;
    width: 177px;
    background: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.32);
    position: relative;
} 
.photo textarea{
    width: 100%;
    border-radius: 3px;
    border: 1px gray solid;
    padding: 10px;
    height: 80px;
}
.photo .loader_photo{
    width: 100%;
    height: 155px;
    position: relative;
    border-radius: 3px;
    z-index: 1;
}

.photo .label_rotate{
  margin-left: 20px;
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 4px 5px;
  background-color: #eee;
  position: absolute;
  margin-top: -30px;
  width: calc(100% - 60px);
  opacity: 0.5;
  cursor: pointer;
  text-align: center;
}
.photo:hover .label_rotate{
    opacity: 1;
}
.photo img{
    width: 100%; 
    border-radius: 3px;
    transition: all 0.5s ease-in-out;
    aspect-ratio: 1/1;
    display: block;
} 
.photo .delete{
    width: 27px;
    height: 27px;
    color: white;
    background-color: black;
    opacity: 0;
    font-size: 13px;
    padding-left: 5px;
    padding-top: 4px;
    float: right;
    clear: both;
    cursor: pointer;
    margin-bottom: -23px;
    margin-right: 24px;
    transition: all 0.5s ease-in-out;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    right: -35px;
    top: -11px;
    border: 2px white solid;
}
.photo:hover .delete{
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.remove_label{
  background-color: #DB2828 !important;
  border-color: #DB2828 !important;
  color: #FFFFFF !important;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 25px;
  position: absolute;
  right: -9px;
  top: -9px;
}
 

@container pb (max-width: 768px) {

    .dropzone_content .imgs{
        padding: 0px;
    }

    .photo{
    width: calc(100% / 4 - 10px);
    aspect-ratio: 1/1;
    height: auto;
    padding: 5px;
    }
 
}



</style>