<template>
  <div class="pb qform">


    <h1>{{ $t('grow_questions_edit_title') }}</h1>



    <GrowerNotVerificated 
      v-if="!useAuth().isVerifiedEmail()"/>
 
      
    <div class="limit_charackters">
      {{currentQuestionTitle}} {{ $t('week_view_question_characters_left') }}
    </div>
 

    <UiInput
      :text="edited_question.title"
      :max="maxQuestionTitle"
      :placeholder="$t('week_view_question_title')"
      @change:text="edited_question.title = $event"
      />

    <!-- <UiWarning
      :message="$t('week_view_question_title_hint')"      
      /> -->
      
    <UiWarning
      :message="'Please use title to formulate your question shorter for people to get a quick glimpse of topic. To ensure that your grow-question is displayed on the main page, it must include a title, the question itself, and a photo.'"      
      />

    <div class="limit_charackters">
      {{currentQuestionText}} {{ $t('week_view_question_characters_left') }}
    </div>

    <UiTextArea
      :text="edited_question.text"
      :max="maxQuestionText"
      :placeholder="$t('week_view_question_placeholder2')"
      @change:text="edited_question.text = $event"
      />


    <div class="uploader">
      <UiUploader
        @uploaded="changePhotos"
        :enable-drag-drop="!edited_question.items_problem_photo?.length"
        />
    </div>



    <div class="photos">

      <QuestionEditPhoto
        v-for="(opt,opt_key) in edited_question.items_problem_photo"
        :key="opt_key"
        :item="opt"
        @remove="removePhoto(opt_key)"
        @update:item="opt = $event"
        />
        
    </div>

    <UiWarning
      v-if="edited_question?.items_problem_photo?.length == 0"
      :message="$t('photo_video_manager_problem_rule')"      
      />

 
    <div v-if="inDiary && diary" class="problem_diary">
      <div class="title">Question of diary</div>
      <div class="val">
        <nuxt-link :to="'/diaries/' + diary.link">
          <img :src="diary.avatar_little" class="avatar">
          {{ diary.name }}
        </nuxt-link>
      </div>    
    </div>

 

    <div class="problems">
      <div class="title_symptom">
        {{ $t('week_view_question_issue_category') }}
      </div>
      <div class="select_symptom">

        <QuestionEditProblemSelectBox  
          @choose="chooseProblem" 
          />
 
          
        <template
          v-for="(item, item_key) in edited_question.items_problem_symptom"
          >                  
          <QuestionEditProblemViewBox                   
            :item="item"          
            @remove="removeProblem(item_key)"
            />
        </template> 
      </div>
    </div>


    <div class="acts">    
      <UiButton          
        @click="save"
        type="primary"
        :is-loading="isLoadingSave"
        :disabled="!useAuth().isVerifiedEmail()"
        :name="$t('week_view_question_ask_my_question')"                  
        />
      <UiButton
        @click="cancel"
        :name="$t('universal_button_cancel')"                  
        />
    </div>

  </div>
</template>

<script setup>


const route = useRoute();
const router = useRouter();
const { $api, $ga, $helper, $head, $popup, $vartpl } = useNuxtApp();
const { t } = useI18n();
const emits = defineEmits(['updateweek']);

const maxQuestionTitle = ref(155);
const maxQuestionText = ref(280);
const link = ref(route.params['id']);
const inText = ref(route.query['text']);
const inDiary = ref(route.query['diary']);
const inWeek = ref(route.query['week']);
const question = ref({});
const edited_question = ref({});
const isLoadingSave = ref(false);
const diary = ref(null);

const initData = () => {
  if(isNaN(link.value)){        
    edited_question.value = JSON.parse(JSON.stringify($vartpl.question));                                  
  }else{         
    edited_question.value = JSON.parse(JSON.stringify(question.value));
  } 
}

const changePhotos = (item) => {      
  if(!edited_question.value.items_problem_photo)
    edited_question.value.items_problem_photo = [];
  edited_question.value.items_problem_photo.push(item);      
}

const removePhoto = (key) => {            
  edited_question.value.items_problem_photo.splice(key,1);      
}

const removeProblem = (item_key) => {     
  edited_question.value.items_problem_symptom.splice(item_key,1);      
}

const chooseProblem = (item) => {     
  edited_question.value.items_problem_symptom.push(item);   
}

const cancel = () => { 
  router.push({ path: '/grow-questions'});
}

const save = () => {            
  isLoadingSave.value = true;
  var form = edited_question.value;              
  if(!form.id){   
    $api.postQuestion(form) 
    .then((res) => {            
      emits('updateweek', res.data.value.data.id);      
      router.push({ path: '/grow-questions/' + res.data.value.data.link })
      isLoadingSave.value = false;
    })
    .catch(function (error) {
      console.log(error);        
      isLoadingSave.value = false;      
      $popup.error(error.message);
    });  
  }else{
    $api.patchQuestion(form.id, form) 
    .then((res) => {            
      emits('updateweek', form.id);    
      isLoadingSave.value = false;
    })
    .catch(function (error) {     
      console.log(error);        
      isLoadingSave.value = false;      
      $popup.error(error.message);     
    });
  }
}



const loadDiary = async function(id) {
  await $api.getDiary(id)
  .then((res) => { 
    // console.log(res.name);
    diary.value = res;
    // return res.data.value.data.title;
  })
}

const currentQuestionTitle = computed(() => {
  if(edited_question.value.title)
    return maxQuestionTitle.value - edited_question.value.title.length;
  else
    return maxQuestionTitle.value;
});

const currentQuestionText = computed(() => {
  if(edited_question.value.text)
    return maxQuestionText.value - edited_question.value.text.length;
  else
    return maxQuestionText.value;
});
 


onMounted(() => {  

  initData();

  if(inText.value){
    edited_question.value.text = inText.value;
  }
  if(inDiary.value){
    edited_question.value.report_id = inDiary.value;
  }
  if(inWeek.value){
    edited_question.value.post_id = inWeek.value;
  }
});

onMounted( () => {   
  if(inDiary.value && import.meta.client){ 
    setTimeout(() => {
      loadDiary(inDiary.value);
    }, 100); 
  }
});

 
</script>

<style scoped>


h1{
  margin-bottom: 2rem;
}
.problem_form{

}
.qform .field{
  margin-bottom:1rem;
}
.qform .hint{
  margin-top: 0.5rem;
  font-weight: 600;
}
.qform .ui.pointing.label:before{
  left: 20px;
}
.qform .modal_box_container{
  position: relative;
}
.qform .problem_title {
  width: 100%;
  margin: 0em;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  margin-bottom: 0;
  position: relative;
}

.qform .textarea_box {
  border: 1px #dededf solid;
  border-radius: 3px;
  padding: 20px;
  margin: 0em;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  margin-bottom: 2rem;
  position: relative;
}
.qform .textarea_box .emoji_popup{
  position: absolute;
  right: 9px;
    bottom: 0px;
      margin-top: auto!important;
}
.qform .textarea_box .camera_textarea{
  position: absolute;
  right: 9px;
  bottom: 0px;
  margin-top: auto!important;
}

.qform .textarea_box .problem_area{
  margin: 0em;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #FFFFFF;
  border: 0;
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  width: 100%;
  min-height: 12rem;
}


.qform .problem_diary{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.qform .problem_diary .title{
  margin-right: 20px;
  font-weight: bold;
  min-width: 150px;
  /* margin-top: 8px; */
}
.qform .problem_diary .val{
  min-width: 220px;
}
.qform .problem_diary .val .avatar{
  
  max-width: 20px;
  border-radius: 5px;
  vertical-align: middle;
  margin-top: -2px;
}

.qform .acts{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

/* problems  */

.qform .problems{
  display: flex;
  align-items: center;
}
.qform .problems .title_symptom{
  margin-right: 20px;
  font-weight: bold;
  min-width: 150px;
  /* margin-top: 8px; */
}
.qform .problems .select_symptom{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.qform .problem_buttons{
     margin-right: auto;     
     margin-top: 20px;     
} 

/* photos  */

.qform .photos{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.uploader{
  margin-top: 5px;
  margin-bottom: 5px;
}
/*  */
.qform .limit_charackters {
    margin-bottom: 5px;
    font-size: 0.85rem;
    color: #9e9e9e;
    font-style: italic;
}
.acts{
  margin-top: 2rem;
}
.acts > *{
  margin-right: 1rem;
}


@container pb (max-width: 768px){

  .qform .problems{
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    align-items: flex-start;
  }

  .qform .problems .sb{ 
  }
  
  .qform .problems .select_symptom{
  }
}

</style>
