<template>

  <div class="sb"  >
 

    <div @click="toggleSearchBox">
      <i class="icon-plus icplus"></i> 
      {{ $t('week_view_question_ask_add_symptom') }}
    </div>
 
    <QuestionEditProblemSelectBoxSearch   
      v-if="showed"
      :showed="showed"   
      @choose="chooseSearchItem"        
      @close="showed = false"    
      />

  
  </div>
  
</template>


<script>
 
export default {
  components: {         
 
  },  
  props:[             
  ],
  data() {
    return {          
      showed: false
    };
  }, 
 
  methods: { 
    clear(){ 
    }, 
    chooseSearchItem(item){  
      console.log('chooseSearchItem');
      console.log(item);
      this.$emit('choose', item);
      this.hideSearchBox();
    },  
    toggleSearchBox(){
      this.showed = !this.showed;
    },
    hideSearchBox(){
      this.showed = false;
    },
  }
}
</script>
 


<style scoped>
   


/* .edit_diary .strains{display: flex;align-items: flex-start;flex-wrap: wrap;} */

.sb{ 
  padding: 10px;
  background-color: #dededf;
  border-radius: 30px;
  padding: 10px 13px;
  background-color: #ffffff;
  border-radius: 53px;
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px; 
  cursor: pointer;
  font-weight: bold;
  color: #4c4c4c;
  margin-right: 2px;
  background-color: #f1f1f1;
  position: relative; 
}

.sb:not(.created){
    cursor: pointer;
}  
.sb .names{
    /* margin-right: auto; */
    width: 100%;
}
.sb .names .name{
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
}

.name {}
.sb .names .sec_name{
    color: gray;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sb .action{width: 24px;position: absolute;top: -5px;right: 5px;}

.sb .types{display: flex;width: calc(100% + 2rem);margin-left: 0rem;margin-top: 0.5rem;background-color: var(--un-primary-back-color);border-radius: 0 0 19px 19px;box-shadow: inset 0px 4px 10px #0000000f;border-top: 1px white solid;}
.sb .types .type{
  width: 50%;
  text-align: center;
  padding: 10px;
  /* background-color: var(--un-primary-back-color); */
  color: #ffffff94;
  cursor: pointer;
}
.sb .types .type:first-child{
  border-radius: 0 0 0 19px;
  padding: 10px 5px 10px 15px;
}

.sb .types .type:last-child{
  border-radius: 0 0 19px 0;
  padding: 10px 15px 10px 5px;
}
.sb .types .type:hover,
.sb .types .type.active{
  color: white;
}
.sb .cnt{display: flex;align-items: center;justify-content: center;margin-top: 0.5rem;}
/* .edit_diary .new_strain .sb .cnt{
  pointer-events: none;
} */
.edit_diary .new_strain .sb .types{
  pointer-events: none;
}
.sb .cnt .mn{
  /* background-color: #aaaaaa; */
  /* color: white; */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 20px;
  font-weight: bold;
  line-height: 22px;
  cursor: pointer;
  font-size: 15px;
}
.sb .cnt .pl{
  /* background-color: #aaaaaa; */
  /* color: white; */
  width: 24px;
  height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 20px;
  font-weight: bold;
  line-height: 22px;
  cursor: pointer;
  font-size: 15px;
}
.sb .cnt .cn{
  margin: 0 0.5rem;
  font-weight: bold;
}
.sb .action .btn_trash{
background-color: #DB2828 !important;
    border-color: #DB2828 !important;
    color: #FFFFFF !important;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 25px;
    position: absolute;
    right: -10px;
}
.sb .action .btn_trash i{
  
}



@comtainer pb (max-width: 768px) {
  .sb {
    min-height: auto;
    flex-direction: row;
    margin-right: 0;

    background-color: #f3f3f3;
    flex-wrap: wrap;
  }
  .sb .bank_logo{
    width: 40px;
    margin-right: 10px;
    height: 40px;
    overflow: hidden;
  }
  .sb .names{ 
    text-align: left;
    justify-content: left;
    margin-right: auto;
    width: calc(100% - 150px);
  }
  .sb .action{
    position: absolute;
    right: 0;
    top: 0;
  }
  .edit_diary .new_strain .sb{

  }
  .edit_diary .new_strain .sb .bank_logo{
    height: 40px;
    overflow: hidden;
  }
  .edit_diary .new_strain .sb .bank_logo img{
    width: 59px;
    margin-left: -14px;
    margin-top: -15px;
  }
  .sb .names .name{
    text-align:left;
  }
  .sb .names .sec_name{
    text-align:left;
  }
  .edit_diary .new_strain{
    width:100%;
  }

  .sb .cnt{
    width: 80px;
    margin-top: 0rem;
  }
  .sb .types {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 0 0 10px 10px;
  }
  .sb .types .type:first-child {
    border-radius: 0 0 0 19px;
    padding: 5px 5px 5px 15px;
  }
  .sb .types .type:last-child {
    border-radius: 0 0 19px 0;
    padding: 5px 15px 5px 5px;
  }


}


</style>