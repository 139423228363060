<template>

 
<Teleport to="#app">
    <UiModal

      :title="$t('week_view_question_ask_add_symptom')"   
      close-button-name="Close"
      :is-auto-height="true"
      :width="'100%'"
      :max-width="'600px'"
      @close="close()"  
      >        

    <div class="frm">

      <div class="search">
 
        <div class="inpvl">
          <GeneralSearchInput 
            @search="chooseKeyword"
            :val="keyword"
            :cl="''"
            :placeholder="$t('week_view_question_ask_add_symptom')"            
            />        
        </div>
      </div>
 

      <div class="list">
             
        <template v-for="item in list">
          <div class="item" @click="chooseItem(item)">            
            {{ item.trkey ? $t(item.trkey) : '' }}
          </div>   

          <template v-for="item_child in item.childs">
            <div class="item child" @click="chooseItemChild(item, item_child)">            
              {{ item_child.trkey ? $t(item_child.trkey) : '' }}
            </div>   
          </template>
          
        </template>
      

      </div>   
    
    </div>
    </UiModal>
  </Teleport>
  
</template>


<script>

export default {
  components: {
    
  },
  props:[
    'showed', 
  ],
  data() {
    return {
      keyword: '',       
      category: null,      
      list: [],       
    };
  }, 
  mounted () {
   
    this.loadResults();
  },
  watch: {    
    showed: function(newVal, oldVal) {       
      if(newVal){      
        this.loadResults();
      }
    }, 
  }, 
  computed: {  
  },
  methods: {  
    close(){
      console.log('modal event close')
      this.clear();
      this.$emit('close');
    },
    clear(){ 
      this.keyword = '';
      this.category = null;
    },
    chooseItemChild(item, item_child){

      var ret = {}; 
      ret.id = item_child.id.replace('symptom_','');       
      ret.type = item.id.replace('symptom_top_','');
  
      this.$emit('choose', ret);
      this.clear();

    },
    chooseItem(item){
      this.category = item.id;
      this.loadResults();
    }, 
    chooseKeyword(keyword){
      this.keyword = keyword; 
      this.loadResults();
    },
    categoryFilter(original){
      var list = {};
      var original = JSON.parse(JSON.stringify(original));

      for(var i in original){

        if(i == this.category){
          list[i] = JSON.parse(JSON.stringify(original[i]));
        }         
      } 

      return list;
    },
    keywordFilter(original){
      var list = {};
      var original = JSON.parse(JSON.stringify(original));

      for(var i in original){
        var item = original[i];
        
        for(var c in item.childs){
          var child = item.childs[c];             
     
          if(child){            
            var child_name = this.$t(child.trkey).toLowerCase();            
            if(child_name.indexOf(this.keyword.toLowerCase()) >= 0){                            
              if(!list.hasOwnProperty(i)){
                list[i] = JSON.parse(JSON.stringify(item));
                list[i].childs = {};
              }
              list[i].childs[c] = child;              
            }
          }
        }
      } 

      return list;
    },
    loadResults(){
      this.list = JSON.parse(JSON.stringify(this.$constants.symptoms));
      if(this.keyword.trim())
        this.list = this.keywordFilter(this.list);
      if(this.category)
        this.list = this.categoryFilter(this.list);
     }
  }
}
</script>
 


<style scoped>
   

.modal_sel{
  background-color: white;    
  vertical-align: middle;
  width: 100%;
  text-align: left; 
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 300px;
  height: 410px;
  border-radius: 5px;
  padding: 1rem;
  display: none;
  border: 1px #dededf solid;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 7%);
}
.modal_sel.showed{
  display: block;
}
/* form */

.frm{
  height: 100%;
}
.frm .search{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.frm .search .logo_selected{
  width: 25px;
  margin-right: 10px;
  height: 25px;
  border: 0;
}
.frm .search .inpvl{
  width: calc(100% - 30px);
}
.frm .search .inpvl input{
  width: 100%;
}
.frm .search .inp_val{

}


.frm .list{
    overflow-y: auto;
    max-height: 340px;
}

.frm .list .item{
  padding: 4px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.frm .list .item.child{
  padding-left: 10px;
  font-weight: normal;
}
 

@media (max-width: 768px) {

  .frm .list{
    max-height: calc(100% - 70px);
  }


}


</style>